import { Scene } from "phaser";
import store from "@/store/index.js";
import i18n from "@/plugins/i18n";

export default class Hint extends Scene {
  constructor() {
    super({ key: "Hint" });
  }

  init(data) {
    this.sceneName = data.sceneName;
    this.bookKey = data.bookKey;
    this.hintImage = data.hintImage;
  }

  create() {
    this.scene.bringToTop();
    this.add.rectangle(240, 427, 480, 854, 0x000000).setAlpha(0.7);
    this.add.image(240, 340, "imgSettingPanel").setScale(1.2, 1.3);

    this.add.image(240, 320, this.hintImage).setDisplaySize(250, 250);

    this.add
      .image(240, 490, "imgOptionBtn")
      .setScale(0.25)
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", this.clickClose, this);
    this.add
      .text(240, 490, i18n.t("action.ok"), {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 16,
        color: "#000",
      })
      .setOrigin(0.5);
  }

  clickClose() {
    this.simpleClickSound = this.sound.add("sfxSimpleClick");
    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
    this.scene.stop();
    this.scene.resume(this.sceneName);
  }

  update() {
    //
  }
}
