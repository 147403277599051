import Phaser from "phaser";
import LoadingScene from "@/hamochiABC/reader/scenes/LoadingScene";
import StoryScene from "@/hamochiABC/reader/scenes/StoryScene";
import GameScene from "@/hamochiABC/reader/scenes/GameScene";
import MapScene from "@/hamochiABC/reader/scenes/MapScene";
import QuizScene from "@/hamochiABC/reader/scenes/QuizScene";
import NilamScene from "@/hamochiABC/reader/scenes/NilamScene";
import ItemScene from "@/hamochiABC/reader/scenes/ItemScene";
import Settings from "@/hamochiABC/reader/scenes/Settings";
import Hint from "@/hamochiABC/reader/scenes/Hint";


function launch(containerId) {
  return new Phaser.Game({
    type: Phaser.AUTO,
    backgroundColor: 0xffffff,
    parent: containerId,
    scale: {
      parent: containerId,
      mode: Phaser.Scale.FIT,
      autoCenter: Phaser.Scale.CENTER_BOTH,
      width: 480,
      height: 854,
    },
    physics: {
      default: "arcade",
    },
    plugins: {
      global: [
      ],
      scene: [
      ],
    },
    dom: {
      createContainer: true,
    },
    scene: [
      LoadingScene,
      StoryScene,
      GameScene,
      MapScene,
      QuizScene,
      NilamScene,
      ItemScene,
      Settings,
      Hint,
    ],
  });
}

export default launch;
export { launch };
